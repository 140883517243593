import { isServer } from "./env";

function getCurrentScreenSize(): {
  screenDesktop: boolean;
  screenTablet: boolean;
  screenMobile: boolean;
  screen: string;
} {
  if (isServer) {
    return {
      screenDesktop: false,
      screenTablet: false,
      screenMobile: false,
      screen: "",
    };
  }
  if (window.matchMedia("(max-width: 767px)").matches) {
    return {
      screenDesktop: false,
      screenTablet: false,
      screenMobile: true,
      screen: "phone",
    };
  } else if (window.matchMedia("(max-width: 991px)").matches) {
    return {
      screenDesktop: false,
      screenTablet: true,
      screenMobile: true,
      screen: "tablet",
    };
  } else if (window.matchMedia("(max-width: 1199px)").matches) {
    return {
      screenDesktop: true,
      screenTablet: false,
      screenMobile: false,
      screen: "desktop",
    };
  }
  return {
    screenDesktop: true,
    screenTablet: false,
    screenMobile: false,
    screen: "large-desktop",
  };
}

const {
  screen,
  screenDesktop: isDesktop,
  screenTablet: isTablet,
  screenMobile: isMobile,
} = getCurrentScreenSize();

export { screen, isDesktop, isMobile, isTablet };
